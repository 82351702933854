export default [

]
export function dataInJs() {
	var phoneCode = [
    {"value":"+852","label":"中國香港"},
    {"value":"+86","label":"中國"},
    {"value":"+853","label":"中國澳門"},
    {"value":"+886","label":"中國台灣"},
    {"value":"+33","label":"法國"},
    {"value":"+82","label":"韓國"},
    {"value":"+1","label":"美國"},
    {"value":"+44","label":"英國"},
    {"value":"+65","label":"新加坡"},
    {"value":"+34","label":"西班牙"},
    {"value":"+54","label":"阿根廷"},
    {"value":"+49","label":"德國"},
    {"value":"+81","label":"日本"},
    {"value":"+66","label":"泰國"},
    {"value":"+84","label":"越南"},
    {"value":"+95","label":"緬甸"},
    {"value":"+39","label":"意大利"},
    {"value":"+351","label":"葡萄牙"},
    {"value":"+91","label":"印度"},
    {"value":"+1684","label":"美屬薩摩亞"},
    {"value":"+43","label":"奧地利"},
    {"value":"+61","label":"澳大利亞"}
	];
	return phoneCode;
}
