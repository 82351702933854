<template>
  <div class="my">
    <div class="head">
      <div class="title">
        <p>為了更好地使用，</p>
        <p>請登錄後使用 NFT Market 功能</p>
      </div>
      <div class="form">
        <div class="input">
          <!-- <el-dropdown size="small" trigger="click" placement="bottom-start">
            <span class="el-dropdown-link" style="cursor: pointer;">
              {{ value }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="value in areaCode" :key="value.value">{{ value.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <el-select class="prefix" v-model="value">
            <el-option
              v-for="item in areaCode"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
              <span>{{ item.value }}</span>
              <span>{{ item.label }}</span>
            </el-option>
          </el-select>
          <div class="wire"></div>
          <el-input
            class="right_input"
            v-model.trim="formData.phone"
            placeholder="請輸入手機號碼"
            type="text"
            oninput="value=value.replace(/[^\d.]/g,'')"
            @blur="salaryChange"
          ></el-input>
        </div>
        <div class="input inputCode" style="margin-top: 18px;" slot="footer">
          <el-input
            :style="{ width: phoneCode ? '235px' : '' }"
            v-model.trim="formData.code"
            placeholder="請輸入驗證碼"
            type="text"
            oninput="value=value.replace(/[^\d.]/g,'')"
            @blur="salaryChange2"
          ></el-input>
          <el-button v-show="!phoneCode" class="btn" @click="GetCodeBtn"
            >獲取</el-button
          >
          <span v-show="phoneCode" class="phoneCode" @click="NoCodeBtn">{{
            phoneCode + '秒後重新獲取'
          }}</span>
        </div>
      </div>
      <div class="login">
        <!-- <div class="agreement">
          <el-checkbox v-model="checked"></el-checkbox>
          <p>閱讀並同意<span @click="privacyDialog('用戶協議')" style="color: #ffd92a;cursor: pointer;">《用戶協議》</span>和<span @click="privacyDialog('隱私條例')" style="color: #ffd92a;cursor: pointer;">《隱私條例》</span></p>
        </div> -->
        <el-button class="btn" @click="loginBtn">登錄</el-button>
      </div>
    </div>
    <p class="out" @click="outBtn">取消</p>
    <new-dialog :isShowDialog.sync="isShowDialog" :title.sync="title" @showDialog="isShowDialog = false"></new-dialog>
  </div>
</template>

<script>
import { checkPhone } from '@/common/common.js'
import { dataInJs } from '@/common/phoneArea'
import newDialog from '@/components/Dialog/dialog.vue'
import { TencentCode, getCode, login } from '@/api/api.js'
import '@/assets/js/TCaptcha.js'
import { mapMutations } from 'vuex'
export default {
  components: {
    newDialog
  },
  data() {
    return {
      isShowDialog: false,
      formData: {
        phone: '',
        code: '',
      },
      checked: false,
      value: '+852', // 手机前缀
      areaCode: dataInJs(),
      phoneCode: 0,
      timer: null,
      title: '',
    }
  },
  computed: {},
  mounted() {
  },
  methods: {
    ...mapMutations(['setTokenInfo', 'setLoginShow']),
    privacyDialog(title) {
      this.isShowDialog = true
      this.title = title
    },
    GetCodeBtn() {
      var Captcha = new TencentCaptcha('2009916787', async(res) => {
        // 返回结果
        // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
        // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
        // CaptchaAppId       String    验证码应用ID。
        // bizState    Any       自定义透传参数。
        // randstr     String    本次验证的随机串，请求后台接口时需带上。
        if (res.ret == 0) {
          //     TencentCode({ randstr: res.randstr, ticket: res.ticket }).then(res => {
          //   console.log(res, '这里')
          // }).catch(err => {
          //   console.log(err, '错误')
          // })
          const res2 = await TencentCode({
            randstr: res.randstr,
            ticket: res.ticket,
            permission: 'no',
          })
          if (res2.data.unlockLogin) {
            const res3 = await getCode({
              mobile: this.value + this.formData.phone,
              permission: 'no',
            })
            if (res3.data.countDown) {
              this.$message.success('發送成功')
              this.phoneCode = res3.data.countDown
              this.timer = setInterval(() => {
                if (res3.data.countDown > 0 && this.phoneCode > 0) {
                  this.phoneCode--
                } else {
                  clearInterval(this.timer)
                  this.phoneCode = 0
                }
              }, 1000)
            }
          }
        } else {
          this.$notify.warning('請通過驗證！')
          // console.log(res)
        }
      })
      if (this.formData.phone && checkPhone(this.formData.phone)) {
        Captcha.show()
        var title = document.getElementById('guideText')
        console.log(title, '这里')
      } else if (!this.formData.phone) {
        this.$message.error('請輸入手機號碼！')
      } else if (!checkPhone(this.formData.phone)) {
        this.$message.error('請輸入正確的手機號碼！')
      }
    },
    NoCodeBtn() {
      this.$message.error('請不要頻繁操作')
    },
    async loginBtn() {
      if (
        this.formData.code &&
        checkPhone(this.formData.phone)
      ) {
        const res = await login({
          code: this.formData.code,
          mobile: this.formData.phone,
          mobilePrefix: this.value,
          permission: 'no',
        })
        let userInfo = res.data.user
        userInfo.token = res.data.token
        this.setTokenInfo(userInfo)
        this.$message.success('登錄成功')
        this.formData.phone = ''
        this.formData.code = ''
        this.checked = false
        this.value = '+852'
        this.setLoginShow(false)
        this.$emit('updateMarketList')
      } else if (!this.formData.phone) {
        this.$message.error('請輸入手機號碼！')
      } else if (!checkPhone(this.formData.phone)) {
        this.$message.error('請輸入正確的手機號碼！')
      } else if (!this.formData.code) {
        this.$message.error('請輸入驗證碼！')
      } else if (this.formData.code.length > 6) {
        this.$message.error('驗證碼不能多於6位！')
      }
      //  else if (!this.checked) {
      //   this.$message.error('請閱讀並同意協議和條例！')
      // }
    },
    outBtn() {
      this.setLoginShow(false)
    },

    salaryChange(e) {
      this.formData.phone = e.target.value
    },
    salaryChange2(e) {
      this.formData.code = e.target.value
    },
  },
}
</script>

<style></style>
