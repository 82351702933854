<template>
  <div class="market">
    <div class="market-bx">
      <div class="mearket_list">
    <div class="hint"></div>
    <div class="head_logo"></div>
    <div class="below_cotent">
      <div class="below_left">
        <div class="head">
          <div class="price" @click="priceSort">
            <img src="/img/market_group 9.png" alt="" />&nbsp;Price
          </div>
          <div class="brush" @click="reset">
            <img src="/img/market_shape.png" alt="" />
          </div>
        </div>
        <div class="NFT_Chain">
          <div class="title">NFT Chain</div>
          <div
            :class="['Solana', item.isSeries ? 'bgs' : '']"
            v-for="item in ChainList"
            :key="item.id"
            @click="ChainActive(item)"
          >
            <img style="width: 29px; height: 29px;" :src="item.img" alt="" />{{ item.name
            }}<img
              :class="item.isSeries ? 'activeGou' : 'image2'"
              :src="!item.isSeries ? '' : '/img/marketPath6.png'"
              alt=""
            />
          </div>
        </div>
        <div class="NFT_Series">
          <div class="title">NFT Series</div>
          <div
            class="SeriesBtn"
            v-for="item in SeriesList"
            :key="item.index"
            @click="SeriesBtn(item)"
          >
            <img :src="item.logoZhTw" alt="" />
            <img
              v-show="item.isSeries"
              class="activeSeries"
              src="/img/marketgou.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="below_right">
        <!-- 暂无数据 -->
        <div v-if="noData === 1" class="noData">
          <img src="/img/Group44@2x.png" alt="" />
          <div style="margin-bottom: 10px;">暫無數據</div>
          <div>No data</div>
        </div>
        <!-- 暂无数据 -->
        <div v-else class="right_img" v-for="item in List" :key="item.index">
          <div class="top" @click="detailsBtn(item)">
            <img class="img" :src="item.nftPath" alt="" width="100%" />
            <img
              @click.stop="magnifyBtn(item.nftPath)"
              class="search"
              src="/img/market_zoom.png"
              alt=""
            />
            <div class="Solana">
              <!-- <img src="/img/SOLANA.png" alt=""> -->
              <img
                :src="
                  item.chain === 'Solana'
                    ? '/img/SOLANA.png'
                    : item.chain === 'Ethereum'
                    ? '/img/marketgroup9copy.png'
                    : '/img/marketgroup10copy.png'
                "
                alt=""
              />
              <span>{{ item.chain }}</span>
            </div>
          </div>
          <div class="bottom">
            <div class="name_price">
              <span class="name">{{ item.code }}</span>
              <span class="price">{{
                priceDispose(item.price) + '&nbsp;' + item.currency
              }}</span>
              <span class="price">{{
                item.status === 'sold'
                  ? '已售'
                  : item.status === 'locked'
                  ? '交易中'
                  : '可售'
              }}</span>
            </div>
            <div class="zan" @click="updateLike(item)">
              <!-- /img/market_zan.png -->
              <img
                :src="
                  LikeList.some((v) => v.nftId === item.nftId)
                    ? '/img/like@2x.png'
                    : '/img/market_zan.png'
                "
                alt=""
              />
              <span>{{ item.likes }}</span>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            :hide-on-single-page="page.total <= 1 ? true : false"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[18, 27, 36, 45]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <login-box
      @updateMarketList="updateMarketList"
      v-if="loginShow"
      class="login_box"
    ></login-box>
    <el-dialog width="35%" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
    </div>
  </div>
</template>
<script>
import LoginBox from '@/components/login/login-box.vue'
import { mapState, mapMutations } from 'vuex'
import {
  marketList,
  getSysLikeList,
  updateLike,
  getCategoryList,
} from '../../api/api'
import { priceDispose } from '@/common/common.js'
export default {
  name: 'nftmarketbox',
  components: {
    LoginBox,
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      LikeList: [], // 查看用户点赞数据
      noData: 0,
      page: {
        page: 1,
        pageSize: 18,
        total: 0,
      },
      priceDispose,
      sort: '',
      List: [
        // {
        //   id: 1,
        //   nftPath: '/img/market_right.png',
        //   name: 'Solana',
        //   code: '111',
        //   status: 'sold',
        //   likes: '99',
        // }
      ], // 右侧内容数据
      ChainList: [
        {
          id: 1,
          img: '/img/SOLANA.png',
          name: 'Solana',
          isSeries: false,
        },
        // {
        //   id: 2,
        //   img: '/img/marketgroup9copy.png',
        //   name: 'Ethereum',
        //   isSeries: false,
        // },
        {
          id: 3,
          img: '/img/Group 9 Copy@2xnew.png',
          name: 'Polygon',
          isSeries: false,
        },
        // {
        //   id: 3,
        //   img: '/img/marketgroup10copy.png',
        //   name: 'Polygon',
        //   isSeries: false,
        // },
      ], // Chain数据
      SeriesList: [], // Series数据
    }
  },
  computed: {
    ...mapState(['loginShow', 'userInfo']),
    newChain() {
      return this.newFormData(this.ChainList)
    },
    newSeries() {
      return this.newFormData(this.SeriesList)
    },
  },
  created() {
    this.getCategoryList()
    this.marketList()
  },
  methods: {
    ...mapMutations(['setLoginShow']),
    // 子组件通知父组件更新数据
    updateMarketList() {
      this.marketList()
    },
    async getCategoryList() {
      const res = await getCategoryList()
      res.data.forEach((item) => {
        item.isSeries = false
      })
      this.SeriesList = res.data
    },
    async updateLike(obj) {
      let isLike = null
      let LikeList = this.LikeList
      if (LikeList.length === 0) {
        isLike = '1'
      } else {
        var a = LikeList.some((item) => {
          return item.nftId === obj.nftId
        })
      }
      if (a) {
        isLike = '0'
      } else {
        isLike = '1'
      }
      const res = await updateLike({
        mobile: this.userInfo.name + this.userInfo.mobile,
        nftId: obj.nftId,
        isLike,
      })
      this.marketList()
      this.$message.success(res.message)
    },
    // async getSysLikeList() {
      
    // },
    newFormData(List) {
      let newArr = []
      List.forEach((item) => {
        if (item.isSeries) {
          newArr.push(item.name)
        }
      })
      return newArr
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.marketList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.page.page = val
      this.marketList()
    },
    async marketList() {
      const marketList2 = marketList({
        page: this.page.page,
        pageSize: this.page.pageSize,
        chain: this.newChain,
        category: this.newSeries,
        sort: this.sort,
      })
      const res = await marketList2
      if(this.userInfo.token) {
        const getSysLikeList2 = getSysLikeList()
        const res2 =  await getSysLikeList2
        this.LikeList = res2.data
      }
      this.List = res.data.result
      this.page.total = res.data.total
      if (this.List.length === 0) {
        this.noData = 1
      } else {
        this.noData = 0
      }
    },
    ChainActive(item) {
      this.page.page = 1
      item.isSeries = !item.isSeries
      this.marketList()
    },
    SeriesBtn(item) {
      this.page.page = 1
      item.isSeries = !item.isSeries
      this.marketList()
    },
    detailsBtn(obj) {
      this.$router.push(
        `/market/marketDetails?nftCommodityId=${obj.nftCommodityId}`
      )
    },
    magnifyBtn(url) {
      this.dialogVisible = true
      this.dialogImageUrl = url
    },
    // 把左边筛选全部重置
    reset() {
      this.ChainList.forEach((item) => {
        item.isSeries = false
      })
      this.SeriesList.forEach((value) => {
        value.isSeries = false
      })
      this.sort = ''
      this.marketList()
    },
    // 控制右边商品列表价格排序
    priceSort() {
      if (this.sort === '1') {
        this.sort = '0'
      } else if (this.sort === '0') {
        this.sort = '1'
      } else if (this.sort === '') {
        this.sort = '1'
      }
      this.marketList()
    },
  },
}
</script>

<style></style>
