<template>
  <el-dialog
    :title="title"
    center
    style="font-size: 18px;"
    :visible.sync="isShowDialog"
    width="50%"
    @close="closed"
    :before-close="closed"
    :append-to-body="true"
  >
    <div v-if="title === '用戶協議'" class="newContent">
      HunterGroup用戶服務協議
      <br />
      【導言及提醒】
      在使用HunterGroup數字藏品平台時，您應當閱讀並遵守《HunterGroup用戶服務協議》及《HunterGroup隱私協議》。請您需審慎閱讀、充分理解本協議的各個條款，包括第三條的數字藏品購買和轉贈規則、第四條的平台服務說明及使用規範、第五條用戶違約及處理措施、第八條免責事項、第十條的法律適用和爭議解決以及附件的《違規用戶處罰規則》
      等可能影響您權利和責任的相應條款。您確認，一旦您以在線點擊確認或其他方式簽署本協議，即視為您己閱讀並同意本協議的所有條款，知曉並理解本協議所有條款的含義且願意承擔相應的法律後果。本協議視為已於您在線點擊確認或以其他方式簽署本協議之日有效簽署，對各方成立並生效。
      我們關注且重視未成年人權益的保障，若您未滿十八週歲，請在監護人的陪同下閱讀和判斷是否同意本協議。您點擊確認或其他類似按鈕並繼續使用本服務即視為您已經取得監護人的必要同意。
      <br />
      【協議的範圍】
      <br />
      《HunterGroup用戶服務協議》（以下簡稱「本協議」）是由Hunter Group Holdings
      Limited（作為開發者&運營方，以下簡稱「HunterGroup」「HunterGroup」或「我們」）簽署的就HunterGroup向您提供數字藏品平台服務（以下簡稱「本服務」）所訂立的有效合約。
      本協議的內容，包括但不限於以下與本服務相關的協議、規則、規範以及我們可能不斷發佈的關於本服務的相關協議、規則、規範等內容。前述內容一經正式發佈，即為本協議不可分割的組成部分，與其構成統一整體，您同樣應當遵守：
      <br />
      1.《HunterGroup用戶服務協議》；
      <br />
      2.《HunterGroup隱私協議》。
      <br />
      HunterGroup作為本服務的運營主體以及服務提供方，有權不經另行通知變更軟件運營。變更後的主體將繼續按照本協議約定並向您提供服務，上述主體的變更不會影響您本協議項下的權益。請您先仔細閱讀本協議內容，如您對本協議內容或頁面提示信息有任何疑問的，請勿進行下一步操作，並聯繫客服咨詢。
      <br />
      【協議的內容】
      <br />
      一、定義
      <br />
      1.1
      HunterGroup平台或平台：指我們開發運營的數字藏品發行、信息展示和交易的平台，包括Hunter
      Wallet、及其後續我們不時開發的其他App或網頁等。
      <br />
      1.2
      數字藏品：指使用區塊鏈技術進行唯一標識的經數字化的特定虛擬作品、藝術品和商品，包括但不限於數字圖片、音樂、視頻、3D模型等各種形式的虛擬數字藏品。
      <br />
      1.3
      發行方：指在平台發行數字藏品並授權發售方銷售數字藏品的實體或個人。如果您的數字藏品是通過與HunterGroup平台合作的第三方平台兌換、購買或其他方式獲得，並在HunterGroup平台進行展示、應用、使用或獲得服務的，則發行方指在該第三方平台發行數字藏品的實體或個人。
      <br />
      1.4
      發售方：是指經平台的發行方授權，通過平台向您銷售數字藏品並進行收款和開票的實體。在本協議中特指HunterGroup平台。
      <br />
      1.5
      服務：指我們以及我們的關聯方和合作方基於互聯網和區塊鏈，以包含數字藏品平台在內的各種形態（包括未來技術發展出現的新的服務形態）向您提供的各項服務，包括但不限於以下服務：數字藏品的展示、瀏覽、購買、轉贈、爭議處理、訂單管理等相關的信息技術服務；數字藏品的應用場景相關的應用服務；以及將數字藏品在TOHO
      Chain進行存證、管理、薄記及交收等存管服務。
      <br />
      1.6
      平台規則：指已在或後續將在平台發佈的各類規則、規範、公告、說明、解讀、聲明、指引或其他類似內容，具體以平台實際展示為準。
      <br />
      1.7
      應用場景：指平台之外的提供數字藏品信息展示、交互和其他應用活動的各類App、小程序和網頁等應用軟件。
      <br />
      二、賬戶註冊、管理和註銷
      <br />
      2.1
      在您通過平台註冊或使用本服務之前，您應確保您具備與使用本服務及購買、轉贈數字藏品等相適應的民事行為能力，並有能力承擔對應的法律後果和法律責任。如果您是未成年人，您在平台購買轉贈數字藏品或進行其他行為，應獲得您監護人的同意。
      <br />
      2.2
      賬戶註冊：您應當按照平台頁面的提示直接提供您的手機號，開立和註冊您的平台賬戶。當您成功完成註冊程序後，您即取得平台賬戶並成為平台用戶。在平台一個手機號僅能註冊和綁定一個賬戶。為保障您的賬戶安全，您同意按照平台的要求及相關法律法規規定完成賬戶的實名認證。
      <br />
      2.3
      賬戶登入：您可通過您的賬戶名稱（即手機號）和手機驗證碼或其他平台允許的方式登錄平台並使用本服務。
      <br />
      2.4
      賬戶管理：您的賬戶僅限於您本人使用。除法律法規或平台規則另有規定，您不得將您的賬戶以任何方式進行轉讓、出借或出售。請您務必妥善保管賬戶登錄名、短信校驗碼等信息。在您的賬戶登錄狀態下進行的所有操作、發出的所有指令均視為您本人做出。如果因您自身的原因造成您的賬戶被冒用、盜用或非法使用的，我們不承擔由此造成的風險和您的損失。
      <br />
      2.5
      賬戶的限權：我們有權依據本協議約定或者法律法規的規定對您的賬戶進行限權或關閉。在限權或關閉賬戶之前，我們將通知您對賬戶內的數字藏品進行處置。
      <br />
      2.6
      賬戶註銷：您可以申請註銷您的賬戶。在申請註銷賬戶之前，您需對賬戶內的數字藏品進行處置，包括在符合法律法規和平台規則的情況下將數字藏品轉移至另一賬戶。如果在您發起註銷賬戶申請並授權我們處置賬戶之日未能主動對賬戶內的數字藏品進行處置的，視為您同意自願放棄對上述數字藏品包括所有權在內的一切權益。請您注意賬戶一旦成功註銷將不可恢復，即使您在註銷後，向平台申請，以同一手機號再次在平台註冊賬戶，該賬戶也將視為新的用戶賬號，您在原註銷賬戶內的數字藏品將無法被找回，因此請您謹慎操作。
      <br />
      三、數字藏品購買
      <br />
      3.1 數字藏品的購買：
      <br />
      3.1.1
      您通過平台購買或兌換數字藏品，應當是已年滿18週歲的合法公民，並且已經通過平台的實名認證流程，請您保證若您未滿18週歲，您的購買或兌換行為是在監護人的陪同下進行或您的上述行為已經取得監護人的同意。
      <br />
      3.2
      您理解並確認，我們有權依據國家法律法規監管政策、平台規則或業務需要對上述購買和轉贈條件進行補充或修改。您與受贈人一旦點擊頁面「同意」按鈕確認本協議或某一平台規則，或者發起購買或轉贈申請，即表示您與受贈人承諾符合購買/轉贈條件並同意受到本協議及平台規則的約束。
      <br />
      四、平台服務說明及使用規範
      <br />
      4.1 平台服務說明：
      <br />
      4.1.1
      您有權在平台上享受數字藏品的瀏覽、購買分享、轉贈、爭議處理、訂單管理以及數字藏品的訪問、欣賞、炫耀、信息查看等信息技術服務，部分數字藏品還可通過平台合作的應用場景進行使用，具體服務內容和功能以平台和應用場景頁面向您實際展示的為準。
      <br />
      4.1.2
      您可以通過平台瀏覽第三方平台的發行方處獲取，或在本平台發行並授權由HunterGroup平台發售的數字藏品及其相關信息。您在HunterGroup平台下單購買前，請您務必仔細確認所購買數字藏品的名稱、介紹、發行方、價格、數量及/或其他限制性要求等重要事項，並對是否購買數字藏品做出獨立決策和判斷。一旦您提交訂單，請在規定時限內完成支付。您支付成功後即視為您與HunterGroup平台之間就已支付價款部分的訂單建立了合同關係。如果您未能在規定時間內完成支付或在支付完成後又退款成功的，我們將回收您訂單項下的數字藏品。特別提醒本平台售出的數字藏品均為虛擬數字商品，而非實物，且因為區塊鏈技術的特性，本平台售出的數字藏品不支持七天無理由退換。
      <br />
      4.1.3
      基於區塊鏈技術的特性，數字藏品具有加密和不可篡改的特點。一旦上鏈，數字藏品元數據中的作者、創作時間和上鏈時間、購買者等信息，在鏈上無法篡改。您購買相關數字藏品後，您的購買者信息將記錄在數字藏品的元數據中，作為您持有該數字藏品的權利憑證。
      <br />
      4.1.4
      數字藏品的圖文展示、介紹、創作者等相關信息均由發行方提供，所有數字藏品的內容和版權由發行方承擔責任。您應當對通過平台接觸到的內容及其準確性、完整性和有效性自行審慎判斷並承擔由此引起的相應風險。除我們存在故意或重大過失外，我們不對您因前述風險而導致的損失或損害承擔責任。
      <br />
      4.1.5
      為保障數字藏品發售交易和資金安全，您同意以平台認可的支付方式作為您購買數字藏品的支付工具。您同時授權平台認可的支付方式的提供商可以按照我們向您發出的指令為您提供資金結算和支付賬戶管理等服務。
      <br />
      4.1.6
      您理解並同意，數字藏品的版權由發行方或原作創作者擁有。除另行取得版權權利人書面同意外，您不得將數字藏品用於任何商業用途。除平台規則或平台功能允許外，您獲得的數字藏品應僅用於自身收藏、學習、研究、欣賞和展示目的，不得用於任何其他目的。
      <br />
      4.1.7
      我們嚴格禁止您利用數字藏品進行售賣、炒作、場外交易、欺詐或以任何其他非法方式進行使用。我們保留隨時根據業務發展和法律法規政策變化進行調整的權利。您認可並同意，為了保障交易安全、有序、合法以及履行我們反洗錢的義務，我們有權對您的區塊鏈地址進行安全查詢。如發現存在售賣、炒作、欺詐等異常行為，或您存在違反法律法規或本協議約定等情形我們有權對您採取本協議第五條約定的必要措施。
      <br />
      4.2 平台服務使用規範：
      <br />
      4.2.1
      您承諾將始終遵守本協議以及與數字藏品相關的訂單、平台規則以及我們不時更新且在平台公示的平台規則，包括但不限於本協議附件一規定的《違規用戶處罰規則》等，並遵守其他第三方服務提供商（如有）與您達成的相關協議或規則。
      <br />
      4.2.2
      您聲明並保證，您對您在使用本服務及購買數字藏品的過程中所提供、發佈的任何信息擁有完整、合法、有效的權利，並且符合相關法律法規及本協議的要求，不存在任何違反法律法規、國家政策以及其他違背社會公共利益、公共道德或妨害平台正常運行、侵犯他人合法權益的信息或行為。您同意我們有權對您提供或發佈的信息內容進行審查，如發現違規或不適宜在平台展示的，我們有權進行刪除或屏蔽處理。
      <br />
      4.2.3
      您應對通過本服務瞭解、接收或接觸到的包括但不限於發行方、發售方或其他用戶在內的任何個人信息和資料予以充分尊重。您不得擅自蒐集複製、存儲、傳播或以其他任何商業目的使用前述個人信息和資料，不得擅自使用任何第三方軟件硬件、插件、外掛、系統等查看、獲取本服務中所包含的我們及我們的關聯方、合作方或發行方、其他用戶的任何相關信息、數據等內容。
      <br />
      4.2.4
      您理解，我們提供本服務有賴於平台系統的準確運行及操作。若出現系統差錯、故障或其他原因引發了展示錯誤、您獲得不當獲利等情形的，您同意我們可以自行或通過我們的關聯方、合作方採取更正差錯、暫停服務、划扣款項等適當糾正措施。
      <br />
      五、用戶違約及處理措施
      <br />
      5.1
      您理解並同意，為了維護交易秩序和交易安全，如我們發現或合理判定您存在違法違規或違反平台約定情形的（以平台公告為準），我們有權採取救濟措施，包括但不限於：
      <br />
      （1）要求您限期糾正違規或違約行為；
      <br />
      （2）主動執行關閉相關訂單；
      <br />
      （3）屏蔽或限制您訪問、使用數字藏品或限制您持有的數字藏品的某些功能；
      <br />
      （4）對違法違規信息進行刪除或屏蔽；
      <br />
      （5）暫停或終止向您提供部分或全部服務；
      <br />
      （6）回收您非法或違規獲得的數字藏品；
      <br />
      （7）採取本協議附件—《違規用戶處罰規則》規定的相關處罰措施；以及
      <br />
      （8）其他合理、合法的措施。
      <br />
      5.2
      如您的行為使我們及/或我們的關聯方和合作方等遭受損失的，我們有權要求您賠償我們的全部損失，包括商譽、支付的賠償金、律師費、訴訟費等。
      <br />
      六、軟件許可
      <br />
      6.1
      我們授予您一項僅限於個人非商業目的、非排他性、可撤銷、不可轉讓、不可分許可的有限的普通許可，您可以在本協議有效期內，按照本協議及平台規則限定的範圍和方式使用Hunter
      Wallet
      手機客戶端軟件（「本軟件」）及相關平台服務。未經我們另行書面許可，您不得為商業目的下載、安裝、登錄和使用本軟件。本協議未明示授權的其他一切權利仍由我們或相關權利方保留。
      <br />
      6.2
      我們會不斷豐富您使用本軟件的終端、內容和形式。您理解由於軟件適配及終端限制，我們可能開發不同的軟件版本。您應當選擇與您的終端、系統等相匹配的軟件版本，否則由此造成的本軟件無法正常運行或您的終端、系統及其軟硬件功能損害的，我們不承擔責任。
      <br />
      6.3
      為了改善用戶體驗、完善服務內容、以及提高軟件安全性等目的，我們有權對本軟件及相關平台服務、功能、界面等進行更新、修改，包括開發新的功能、刪除舊功能等，該等變更不視為我們對本協議的履行存在任何瑕疵，您同意不會因此追究我們的責任。您理解在本軟件版本更新後，我們不保證舊版本的軟件及其服務、功能等可以繼續正常使用，請您隨時關注並將本軟任更新至最新版本。
      <br />
      6.4
      您應當從本軟件的官方網站、官方應用分發平台及/或我們授權的其他第三方網站、應用分發平台等渠道獲取本軟件，如果您從非官方或未經我們授權的第三方獲取本軟件或與本軟件名稱相同的安裝程序，我們無法保證該軟件能夠正常使用和運行，由此產生的任何損害或損失將由您自行承擔。
      <br />
      6.5
      您僅可通過本人人工操作的方式使用本軟件及相關平台服務，不得自行或允許第三方通過非官方程序、軟件、硬件、機器、腳本、爬蟲或其他自動化的方式使用本軟件及相關平台服務或獲取該等軟件和服務數據。
      <br />
      七、用戶信息的保護及授權
      <br />
      我們非常重視對您個人信息的保護，您同意我們依據本協議及《HunterGroup隱私協議》向您收集為您提供本服務所需要的信息並進行合理使用、處理、存儲和共享。我們將遵循合法、正當、必要和誠信、公開、透明的原則，依據適用法律法規及平台隱私杈政策的要求嚴格保護您的個人信息，請您在使用本服務前仔細閱讀《HunterGroup隱私協議》，如有任何疑問請隨時聯繫我們。
      <br />
      八、免責事項
      <br />
      8.1
      我們將努力保障您的合法權益。請您理解，在您購買數字藏品或使用本服務的過程中，可能會遇到不可抗力等風險因素。如出現下列任一情況致使我們無法履行本協議項下約定或您無法完成數字藏品交易的，我們將努力在第一時間與相關單位配合併及時進行修復，但是由此給您或第三方造成的損失，我們不承擔責任：
      <br />
      （1）在與本服務相關的系統停機維護或升級期間；
      <br />
      （2）因自然災害如洪水、地震、瘟疫流行和風暴等以及社會事件如戰爭、動亂、法律法規政策變化和政府行為等不可抗力原因；
      <br />
      （3）您操作不當或通過非我們授權或認可的方式使用本服務；
      <br />
      （4）因病毒、木馬、惡意程序攻擊、網絡擁堵、系統不穩定、系統或設備故障、通訊故障、電力故障、電信設備故障、第三方服務瑕疵等原因。
      <br />
      8.2
      您理解並確認，我們僅以「現狀」向您提供本服務。您在確認接受本協議或使用本服務前已充分瞭解數字藏品平台的功能和服務特性並同意我們無須就軟件瑕疵、功能不足或任何需改進事項對您承擔任何除法律有明確規定的責任。
      <br />
      8.3
      您理解並同意，鑒於網絡服務的特殊性，我們對本服務的持續提供可能受到多種因素的影響，如出現技術升級、服務體系升級、或因經營策略調整或配合國家重大技術、法規政策等變化。我們可能隨時終止、中斷提供服務或變更所提供服務的形式規格或其他方面，而您不會因此追究我們的責任。
      <br />
      8.4
      為便於向您提供服務，我們可能在平台設置相應的外部鏈接。除法律另有明確規定外我們不對外部鏈接的準確性、有效性、安全性和完整性作出承諾或保證。同時，對於該等外部鏈接指向的不由我們實際控制的任何網頁或客戶端上的內容，您同意我們將不承擔相關責任。
      <br />
      8.5
      本協議項下涉及的所有數字藏品，由發行方保證對數字藏品享有作為權利人完整、合法、有效的權利或已取得權利人的合法授權，保證其有權開展數字藏品生成、發行、轉贈等場景的應用活動，保證本協議下的數字藏品業務不違反任何法律法規或侵犯第三方的合法權益，且就在本平台發行的數字藏品，發行方保證有權授權HunterGroup平台進行發售。如因數字藏品發行方或原創作者侵權、發佈危害國家利益、違反公序良俗以及其他可能產生嚴重負面輿情的言論或行為，或者數字藏品存在權利瑕疵、內容違法違規、或其他可能產生嚴重負面輿情等情形導致數字藏品被屏蔽或限制使用的，您可以直接聯繫發行方協商解決，或者您也可以通過我們的客服幫助協調處理。
      <br />
      8.6
      HunterGroup僅就數字藏品在區塊鏈上的信息存證和唯一標識提供區塊鏈技術服務。
      <br />
      九、知識產權
      <br />
      9.1
      平台上的所有內容，包括文字、圖片、檔案、資訊、資料、平台架構、平台畫面的安排、網頁設計等，均由我們或其他權利人依法擁有其知識產權包括但不限於商標權、專利權、著作權、商業秘密等。
      <br />
      9.2
      非經我們或其他權利人書面同意，您不得擅自使用、修改、反向編譯、複製、獲取、公開傳播、改變、散布、發行或公開發表、轉讓、許可、商業利用平台的相關軟件、內容以及其他我們或權利人享有的知識產權，否則我們有權立即終止本協議，您需對您的違約或侵權行為給我們或其他權利人造成的損失承擔相應賠償責任。
      <br />
      十、其他
      <br />
      10.1
      為進一步改善用戶體驗，我們將聯合我們的關聯方和/或合作方持續更新服務，包括但不限於版本升級、功能升級、技術升級、新增、更新或調整服務內容和功能，本協議可能會隨之更新。我們也會根據國家法律法規的變化要求，更新本協議的相關條款。我們會以公告、客戶端信息推送、發送系統消息等通知方式（統稱「通知」）就協議的更新向您進行提示。除通知中另有說明外，更新的內容將於協議更新公告公佈的生效之日起生效。若您不接受變更後的服務內容或功能，或不同意更新後的協議內容，您需立即停止使用相關服務。若您繼續使用本協議項下相關服務的，即視為您己同意我們作出的變更，並自願接受變更後的協議內容約束；雙方協商一致，也可另行變更相關服務和對應條款內容。
      <br />
      10.2
      您理解並同意，出於本服務的業務特性，未經我們書面同意，您不得向第三方轉讓本協議項下的權利和義務。您進一步同意，在不對您使用本服務造成不利影響的前提下，我們可將在本協議項下的權利和義務全部或部分轉讓給我們的關聯方而無需另行通知或徵得您的同意。
      <br />
      10.3
      我們在微信公眾號（HunterGroup）、平台頁面展示的最新服務說明、平台規則等構成本協議不可分割的一部分。如果條款之間存在不一致之處，以
      <br />
      （1）服務說明、平台規則；
      <br />
      （2）本協議的先後順序進行適用。
      <br />
      10.4
      本協議的部分條款無論因何種原因部分無效或不可執行，其餘條款仍有效並對雙方仍具有約束。
      <br />
      10.5
      本協議於廣州市天河區簽訂。本協議之效力、解釋、變更、執行與爭議解決均適用中華人民共和國法律。因本協議產生的爭議，均應提交本協議簽訂地北京市海淀區人民法院管轄。
      <br />
      附件一《違規用戶處罰規則》
      <br />
      為維護數字藏品平台的正常運營，保障各方的合法權益，嚴格防範和打擊任何形式的轉售、炒作數字藏品的違法違規行為，依據
      《HunterGroup用戶服務協議》制定本規則。
      <br />
      1.
      如果我們收到投訴或舉報，或通過其他途徑，經過合理判斷認定您從事了以下違法、違規或違約行為的，我們有權根據您的具體行為和情節實施下述處罰措施，並向您發送處罰通知：
      <br />
      （1）違法、違規或違約行為
      <br />
      違法、違規或違約行為包括但不限於使用外掛、腳本、自動點擊器等非法第三方軟、硬件進行搶購、鎖單等違規作弊行為；違規使用數字藏品、發佈違規或不當信息；其他破壞搶購公平性原則，嚴重影響平台運營和用戶體驗的行為。
      <br />
      （2）平台處罰形式
      <br />
      平台將視情節嚴重程度對認定違規賬號採取階梯式處罰，包括但不僅限於限制訪問、限制購買、限制轉贈、短期封號、永久封號等處罰措施。對極個別行為極其惡劣的用戶，平台保留或行使追究其法律責任的權利，涉嫌違法犯罪的，平台會將違法犯罪數據信息等證據交由司法機關處理。
      <br />
      2.
      針對他人對您的投訴和舉報，或我們對您的處罰措施，您可以提交相關證明材料進行申訴，我們將依據事實進行處理
      <br />
      3.
      您理解並確認，我們有權依據國家法律法規、監管政策、或風險防範的需要對上述處罰規則進行修改、調整、補充和公示。
    </div>
    <div v-if="title === '隱私條例'" class="newContent">
      隱私政策
      <br />
      2022-6-27 02:55:51
      <br />
      我們對隱私保護的承諾
      <br />
      <p>簡介</p>
      歡迎訪問Hunter Group
      數字藝術電商平台（本「數字藝術電商平台」）。本數字藝術電商平台（包括可通過網絡瀏覽器、任何相關應用程序的訪問的域名為域名為www.hunter-wallet.com的網站）由Hunter
      Group Holdings
      Limited（「HunterGroup」）提供、所有和管理。HunterGroup致力於保護您的隱私。
      本數字藝術電商平台由HunterGroup提供，其目的是【為數字藝術品創作者、消費者、收藏家提供數字藝術品交易的平台】。本隱私政策（「政策」）覆蓋HunterGroup通過本數字藝術電商平台收集的或由您直接提供的資料。本政策描述HunterGroup如何通過本數字藝術電商平台向您或代表您收集或從您處或代表您收到任何個人信息（定義見下方）、如何使用、共享、儲存、披露以及保護前述個人信息。
      個人信息指以電子或者其它形式記錄的能夠單獨或者與其它信息結合識別特定自然人身份或者反映特定自然人活動情況的各種信息。
      <br />
      您的角色和責任
      <br />
      ·
      在使用數字藝術電商平台服務前，請您務必仔細閱讀並透徹理解本政策，特別是以粗體/粗體下划線標識的條款，您應重點閱讀，在確認充分理解並同意後再開始使用。對於本政策內容有任何疑問、意見或建議，您可通過數字藝術電商平台提供的各種聯繫方式與我們聯繫。
      <br />
      ·
      您應當知道並有權瞭解在本數字藝術電商平台上收集了哪些個人信息，這些個人信息是如何存儲、保護和使用的。
      您的權利和選擇
      <br />
      · 您有權瞭解本數字藝術電商平台個人信息收集和存儲操作。
      <br />
      · 您有權瞭解您的個人信息將被誰以何種方式使用。
      <br />
      · 您有權瞭解我們可能會向誰共享或披露您的個人信息。
      <br />
      ·
      您有權在任何時候詢問我們是否處理您的個人信息、請求訪問、移除或刪除或移轉（轉移）您的個人信息，並且要求我們對您不準確的個人信息進行糾正或修改。
      <br />
      隱私政策
      <br />
      本政策幫助您瞭解以下內容：
      <br />
      一、我們如何收集您的信息
      <br />
      二、我們如何使用您的信息
      <br />
      三、收集和使用個人信息的其他規則
      <br />
      四、我們會如何共享您的信息
      <br />
      五、我們會如何轉讓您的信息
      <br />
      六、我們會如何披露您的信息
      <br />
      七、共享、轉讓、公開披露個人信息時事先徵得授權同意的例外
      <br />
      八、我們會如何保護您的信息
      <br />
      九、我們會如何儲存您的信息
      <br />
      十、訪問、更正、刪除和清除個人信息
      <br />
      十一、國際傳輸
      <br />
      十二、我們如何處理未成年人的信息
      <br />
      十三、責任/遵守以及如何聯繫我們
      <br />
      十四、其他網站的鏈接
      <br />
      十五、政策更新與變更通知
      <br />
      一、 我們會如何收集您的信息
      <br />
      當您使用我們的服務時，我們需要/可能需要向您收集您的如下兩種個人信息：
      <br />
      1.
      為實現向您提供我們服務的基本功能，您須授權我們收集、使用的必要的信息。如您拒絕提供相應信息，您將無法正常使用我們的產品及/或服務；
      <br />
      2.
      為實現向您提供我們服務的附加功能，您可選擇授權我們收集、使用的信息。如您拒絕提供，您將無法正常使用相關附加功能或無法達到我們擬達到的功能效果，但並不會影響您正常使用我們服務的基本功能。
      <br />
      我們可能向您收集的個人信息，包括通過使用數字藝術電商平台（含下述任何方式）您向我們提供的【您的名字、姓氏、稱謂和/或電子郵箱、聯繫電話號碼或其他聯繫方式、您的聲紋或面部信息、您擬使用的會員名和密碼、您擬用於儲存數字藝術品的數字錢包地址、銀行卡號】等個人基本信息。我們也可能在您使用本數字藝術電商平台的過程中，收集某些您的非個人身份識別資訊，例如您使用的互聯網瀏覽器和操作系統規格、網站瀏覽點擊數據等。
      本政策適用於通過數字藝術電商平台收到（無論是自動地或通過用戶直接輸入的或其他方式收到）的全部個人信息。
      <br />
      我們會為您提供的各項具體功能場景包括：
      <br />
      （一） 當您登錄我們數字藝術電商平台時的個人信息
      <br />
      本數字藝術電商平台需要登錄後使用。為使我們能生成您的數字藝術電商平台登錄信息，我們將向您收集手機號碼、擬使用的登錄名和密碼。如果您拒絕提供上述信息，您將無法註冊數字藝術電商平台賬戶，僅可以使用【瀏覽、搜索】服務。
      <br />
      （二） 向您提供、匹配更契合您需求的產品
      <br />
      如果您希望本數字藝術電商平台向您提供/匹配更契合您需求的產品信息，我們會收集和使用您在訪問或使用本數字藝術電商平台網站或客戶端時的瀏覽、搜索記錄及設備信息、服務日誌信息，提取您的偏好特徵，並向您推送您可能感興趣的產品。包括：
      <br />
      1. 網絡日誌
      <br />
      我們使用標準網絡日誌，記錄訪問我們網站用戶的基本數據。
      <br />
      該等日誌包含如下信息：
      <br />
      · 訪問的日期和時間
      <br />
      · 訪問期間瀏覽的網頁
      <br />
      ·
      訪問站點時使用的電腦IP地址（電腦在聯網時自動生成一個IP地址。IP地址是由互聯網服務提供商分配給您的一個號碼，以便您可以訪問互聯網。IP地址也被用於跟蹤網站的新訪問者和回訪者，或用於網站上使用的問卷）
      <br />
      · 用於瀏覽我們網站的瀏覽器類型
      <br />
      · 在瀏覽本網站之前的網站
      <br />
      我們通常不會通過網絡日誌（包括其IP地址）來識別個人，而且我們也不會向他人披露該等信息。但是，網絡日誌可能被用來識別我們有理由懷疑、試圖破壞我們網站或以未經授權的方式使用IP地址的個人。如果我們有理由相信存在違反計算機安全或相關法律的行為，我們會將向執法機構提供網絡日誌。
      <br />
      2. Cookies和其他技術
      <br />
      為提升您的使用體驗，本數字藝術電商平台的網頁可能採用cookie或相關技術。Cookie是一些設置於您的互聯網瀏覽器、用於儲存您個人喜好的文字檔案，並不會對外透露您的電郵地址或其他個人身份識別資訊。我們也有可能會收集您使用本數字藝術電商平台時所訪問過的網頁、點擊過的鏈接或廣告及進行過的其他操作的相關信息。在您未拒絕接受cookies的情況下，cookies將被發送到您的瀏覽器，並儲存在您的計算機硬盤。您一旦選擇於本數字藝術電商平台輸入您的個人身份識別資訊，該等信息可能會被連結到儲存於cookie的數據。我們使用cookies儲存您訪問我們數字藝術電商平台的相關數據，以便在您訪問或再次訪問我們的數字藝術電商平台時,我們能識別您的身份，並通過分析數據為您提供更好更多的服務。
      您有權選擇接受或拒絕接受cookies。您可以通過修改瀏覽器的設置以拒絕接受cookies，但是我們需要提醒您，因為您拒絕接受cookies，您可能無法使用依賴於cookies的我們數字藝術電商平台的部分功能。
      <br />
      3. 設備信息
      <br />
      當您使用我們的服務或訪問本數字藝術電商平台時，我們也收集您的移動設備發送的某些信息，如設備標識符、用戶設置和您設備的操作系統。我們所收集的有關您的移動設備和互聯網連接的信息可能包括：唯一設備標識符、IMEI號碼、IP地址、操作系統、瀏覽器類型、瀏覽器語言、引用/退出頁面和URLs、移動網絡信息和該設備的電話號碼。我們能讓您下載工具、應用程序和小程序，以使您能夠在任何移動設備上使用服務，該工具、應用程序和小程序會在您的移動設備或其他設備上存儲信息。該等工具會向我們傳輸個人信息，以使您能夠訪問您的用戶帳戶，並且讓我們能夠追蹤這些工具的使用情況。部分工具允許您該將報告和其他信息發送至您的郵箱。我們會使用您傳輸給我們的個人或非識別信息來增強這些工具、開發新的工具、提高質量以及本政策或我們提供的其他通知中所述的其他事項。我們將給您郵箱發送「click-through
      URL」鏈接到我們的數字藝術電商平台內容。當您點擊其中一個URL網址時，您將在到達目標網頁之前通過我們的服務器。我們會跟蹤這些點擊數據，幫助我們確定您對主題的興趣，並評估我們的用戶溝通的有效性。只要不點擊我們發送給您的電子郵件中的任何鏈接或圖像，你就可以避免被追蹤。
      <br />
      二、 我們如何使用您的信息
      <br />
      我們獲取、持有、使用和處理您的個人信息，以提供、保持和管理您的登陸賬號，基於您的個人信息與您的明示同意且出於本政策列出的合法商業目的，為您提供/匹配產品。此外，出於安全目的需要將不時檢查服務器的登陸—例如，檢測有關服務的未經授權活動。該等情況下，服務器登陸數據包括IP地址可能會提供給執法機構，以便他們識別與他們調查的未經授權活動有關的用戶。
      <br />
      （一） 出於我們管理目的使用
      <br />
      我們也可以為我們的管理目的使用您的個人信息，例如考量我們的數字藝術電商平台服務的利益和/或開發我們的數字藝術電商平台服務、確保內部質量控制、遵守法規要求（包括防止潛在的被禁止的或非法的活動），以及執行我們的使用條款。
      HunterGroup可出於我們享有合法利益的其它目的使用個人信息，例如為將來任何的產品匹配與您聯繫、個人或市場研究、反欺詐保護或在您提供個人信息或您作出同意時向您披露的任何其它目的。
      <br />
      （二） 組合數據的使用以及去標識化或匿名化信息
      <br />
      我們組合和使用來自我們網絡日誌和其他技術的非個人信息（組合數據）來研究本數字藝術電商平台是如何被使用的。我們會定期地分析組合數據並使用我們學習到的內容來改進我們的數字藝術電商平台。此外，我們可以與我們的合作夥伴分享組合數據（不包含任何可以識別某一個人的信息）來幫助他們更好地設計他們的數字藝術電商平台。
      HunterGroup可使用您的個人信息和其他關於您的信息來創造匿名和/或組合信息，例如去標識化的個人信息、去標識化的位置信息、有關您訪問本數字藝術電商平台服務所用計算機或設備的信息、本網站或我們創建的關於我們產品和服務的分析。匿名化的和/或組合信息將用於多種功能，包括用於評測訪客對產品的不同部分或功能的興趣和使用。匿名化的和組合的信息不是個人信息，並且我們可以通過多種方式，包括研究、內部分析、解析以及其他法律允許的任何目的，使用該等信息。為HunterGroup及第三方之目的，我們可以以匿名化的或組合的形式（設計用於防止任何人識別出您的身份）在HunterGroup內部及與第三方共享這些信息。我們可以以適用法律允許的任何方式使用和披露這些匿名的、去標識化的和/或組合的信息，此類處理後信息的使用無需另行向您通知並徵得您的同意。
      <br />
      三、 收集和使用個人信息的其他規則
      <br />
      1.
      若你提供的信息中含有其他用戶的個人信息，在向我們的數字藝術電商平台提供這些個人信息之前，您需確保您已經取得合法的授權。
      <br />
      2.
      若我們將個人信息用於本政策未載明的其他用途，或者將基於特定目的收集而來的個人信息用於其他目的，均會事先獲得您的同意。
      <br />
      3.
      若我們從第三方處間接獲取您的個人信息的，我們會在收集前明確以書面形式要求該第三方說明其個人信息來源，以及是否已經就其收集、處理以及向我們提供您的個人信息取得了您的合法授權。除法律明確允許的情形外，我們只會在確認第三方已經取得您的授權同意後再從第三方收集您的個人信息，如果第三方的授權範圍無法涵蓋我們的處理和使用目的時，我們會自行或者要求該第三方徵得您的同意後再行處理您的個人信息。同時，我們的專業安全團隊對個人信息會進行安全加固（包括敏感信息報備、敏感信息加密存儲、訪問權限控制等）。我們會使用不低於我們對自身用戶個人信息同等的保護手段與措施對間接獲取的個人信息進行保護。
      <br />
      4. 徵得授權同意的例外
      <br />
      根據相關法律法規規定，我們在以下情況下收集、使用您的個人信息無需您的授權同意：
      <br />
      · 與我們履行法律法規規定的義務相關的
      <br />
      · 與國家安全、國防安全直接相關的
      <br />
      · 與公共安全、公共衛生、重大公共利益直接相關的
      <br />
      · 與犯罪偵查、起訴、審判和判決執行等司法或行政執法直接相關的
      <br />
      · 出於維護您或其他個人的生命、財產等重大合法權益但又很難得到本人同意的
      <br />
      · 您自行向社會公眾公開的個人信息
      <br />
      ·
      從合法公開披露的信息中收集的個人信息，如合法的新聞報道、政府信息公開等渠道
      <br />
      · 與您簽訂和履行相關協議或其他書面文件所必需的
      <br />
      ·
      用於維護所提供的產品及/或服務的安全穩定運行所必需的，例如發現、處置產品及/或服務的故障
      <br />
      · 法律法規規定的其他情形
      <br />
      5.
      如我們停止運營數字藝術電商平台服務，我們將及時停止繼續收集您個人信息的活動，將停止運營的通知以逐一送達或公告的形式通知您，並對我們所持有的與已關停業務相關的個人信息進行刪除或匿名化處理。涉及兒童個人信息的，我們會並將停止運營的通知及時告知兒童監護人。
      <br />
      四、 我們會如何共享您的個人信息
      <br />
      我們不會與HunterGroup以外的公司、組織和個人共享您的個人信息，但是以下情況除外：
      <br />
      （一） 供應商和服務提供商
      <br />
      我們可能委託授權合作夥伴亦即供應商和服務提供商為您提供某些服務或代表我們履行職能，這種情況下，我們會僅出於本政策聲明的合法、正當、必要、特定、明確的目的共享您的個人信息，以支持您參與或潛在參與HunterGroup活動，並允許我們提供其他活動相關的服務。我們交托個人信息的服務提供商（處理者）的類型包括以下服務提供商：（i）提供IT和相關服務；（ii）提供您要求的產品和服務；（iii）付款處理；以及（iv）客戶服務活動；以及（v）與提供數字藝術電商有關的其他活動。HunterGroup已經與服務提供商簽署了適當的合同，禁止服務提供商使用或共享個人信息，除非是代表我們履行合同約定服務所必要的或為遵守所適用的法律要求所必需的。
      <br />
      （二） 法定情形下與第三方共享信息
      <br />
      我們可能需要與第三方共享有關您的個人信息，以響應執法或國家安全請求或法律程序，如搜查令、傳票或法院命令，或與涉嫌或實際非法活動有關的調查或起訴。披露也可能是為了遵守法律、保護我們或您的合法權利，或他人的合法權利，包括財產或安全，保護我們數字藝術電商平台的安全性或完整性，採取預防措施防範責任，或在人身安全可能受到威脅的緊急情況下，或如果我們善意地相信披露在其他方面是必要的或明智的。雖然這些類型的披露不太可能發生，但它們仍然是可能的，因此我們在這裡對他們進行了提示說明。
      <br />
      五、 我們會如何轉讓您的個人信息
      <br />
      我們不會將您的個人信息轉讓給任何公司、組織和個人，但以下情況除外：
      <br />
      ·
      在獲取明確同意的情況下轉讓：獲得您的明確同意後，我們會向其他方轉讓您的個人信息
      <br />
      ·
      在數字藝術電商平台服務提供者HunterGroup發生合併、收購或破產清算情形，或其他涉及合併、收購或破產清算情形時，如涉及到個人信息轉讓，我們會要求新的持有您個人信息的公司、組織繼續受本政策的約束，否則我們將要求該公司、組織和個人重新向您徵求授權同意
      <br />
      六、 我們會如何披露您的個人信息
      <br />
      我們僅會在以下情況下，公開披露您的個人信息：
      <br />
      · 獲得您明確同意或基於您的主動選擇，我們可能會公開披露您的個人信息
      <br />
      ·
      如果我們確定您出現違反法律法規或嚴重違反數字藝術電商平台相關協議及規則的情況，或為保護數字藝術電商平台用戶或公眾的人身財產安全免遭侵害，我們可能依據法律法規或徵得您同意的情況下披露關於您的個人信息，包括相關違規行為以及數字藝術電商平台已對您採取的措施
      <br />
      七、 共享、轉讓、公開披露個人信息時事先徵得授權同意的例外
      <br />
      根據相關法律法規規定，以下情形中，共享、轉讓、公開披露您的個人信息無需事先徵得您的授權同意：
      <br />
      · 與我們履行法律法規規定的義務相關的
      <br />
      · 與國家安全、國防安全直接相關的
      <br />
      · 與公共安全、公共衛生、重大公共利益直接相關的
      <br />
      · 與犯罪偵查、起訴、審判和判決執行等司法或行政執法直接相關的
      <br />
      · 出於維護您或其他個人的生命、財產等重大合法權益但又很難得到本人同意的；
      <br />
      · 您自行向社會公眾公開的個人信息；
      <br />
      ·
      從合法公開披露的信息中收集個人信息的，如合法的新聞報道、政府信息公開等渠道
      <br />
      請知悉，根據適用的法律，若我們對個人信息採取技術措施和其他必要措施進行處理，使得數據接收方無法重新識別特定個人且不能復原，則此類處理後數據的共享、轉讓、公開披露無需另行向您通知並徵得您的同意。
      <br />
      八、 我們會如何保護您的個人信息
      <br />
      保護作為用戶的您對於我們的業務至關重要。維護一個安全的網絡和服務器系統符合我們的用戶和我們的夥伴的最大利益。我們使用具備行業標準的物理、技術和管理保護措施來處理和存儲個人信息，以防止該等個人信息遭受可預見的風險，例如未經授權的使用、訪問、披露、銷毀和修改。該等安全保障措施包括使用加密、密碼保護和用戶認證訪問權限。雖然我們將盡合理努力創建一個安定、安全和可靠的數字藝術電商平台，但無法保證通過互聯網向本數字藝術電商平台或從本數字藝術電商平台傳輸的任何通信或材料的機密性。
      我們建立了以數據為核心、圍繞數據生命週期進行的數據安全管理體系，從組織建設、制度設計、人員管理、產品技術等方面多維度提升個人信息的安全性。我們會舉辦安全和隱私保護培訓課程，加強員工對於保護個人信息重要性的認識。我們將不定期更新並公開安全風險、個人信息安全影響評估報告等有關內容，您可通過數字藝術電商平台公告方式獲得。
      如果我們的物理、技術或管理防護設施遭到破壞，導致信息被非授權訪問、公開披露、篡改或毀壞，導致您的合法權益受損的，我們會及時啓動應急預案，採取合理救濟措施，以盡可能降低對您個人信息和其他權益的影響。在不幸發生個人信息安全事件後，我們還將按照法律法規的要求向您告知：安全事件的基本情況和可能的影響、我們已採取或將要採取的處置措施、您可自主防範和降低風險的建議、對您的補救措施等。事件相關情況我們將以郵件、信函、電話、推送通知等方式告知您，難以逐一告知個人信息主體時，我們會採取合理、有效的方式發佈公告。同時，我們還將按照監管部門要求，上報個人信息安全事件的處置情況。
      您個人信息的安全也是您責任的一部分。當您通過本數字藝術電商平台註冊時，您應該選擇難以猜測的密碼，並且您必須對您的密碼保密。您不應與任何人分享您的密碼，也不應以不受保護的格式存儲它，例如將其輸入到您的計算機或保存在您的電子郵件中。我們不承擔非故意披露的責任。
      <br />
      九、 我們會如何儲存您的個人信息
      <br />
      您提供給我們的信息可能會由我們根據備份流程定期存檔或存儲，並且保留的時間為該等信息收集時的目的、向您提供我們的產品和服務、解決爭議、建立法律抗辯、進行審計，追求合法的商業目的、執行我們的協議並遵守適用的法律所要求的時間。例如，《中華人民共和國電子商務法》要求商品和服務信息、交易信息保存時間自交易完成之日起不少於三年。
      <br />
      我們判斷個人信息的存儲期限主要依據以下標準：
      <br />
      1、完成與您相關的交易目的、維護相應交易及業務記錄，以應對您可能的查詢或投訴；
      <br />
      2、保證我們為您提供服務的安全和質量；
      <br />
      3、您是否同意更長的留存期間；
      <br />
      4、是否存在關於保留期限的其他特別約定或法律法規規定。
      <br />
      在超出保留期間後，我們會根據適用法律的要求刪除或匿名化處理您的個人信息。
      <br />
      十、 訪問、更正、刪除和清除個人信息
      <br />
      您可以通過向service@hunter-group.io發送電子郵件來訪問、更新、更正或刪除您提供給我們的個人信息。
      <br />
      您可以通過向service@hunter-group.io發送電子郵件，要求我們向您提供我們保存的有關您的個人信息和副本。
      <br />
      如果您對您的個人信息有任何要求或對隱私有任何疑問或意見，請發送電子郵件至XXXXXXXX。請提供您的聯繫信息、本數字藝術電商平台或所涉服務的名稱，以及對您的請求或隱私顧慮的詳細說明。
      <br />
      請注意：
      <br />
      儘管我們善意地為用戶提供訪問其個人信息的權限，但在某些情況下，我們可能無法提供訪問或刪除某些信息，包括但不限於，含有法律特權信息的信息、根據法律必須保留保留的信息、會損害他人的隱私權或其他合法權利的信息、提供訪問權的負擔或費用與個人隱私權的風險不成比例的信息、在商業上是專有的信息。如果我們確定在任何特定情況下應限制訪問，我們將向您解釋為何會做出此決定，並提供聯繫點供您任何進一步問詢。為保護您的隱私，我們將採取商業上合理的步驟，在允許訪問或更改您的個人信息之前驗證您的身份。
      我們數據庫中不可識別的信息不會被刪除。與所有負責任的數字藝術電商平台一樣，我們保留備份文件以防自然災害、設備故障或其他中斷。備份文件保護了您和我們，因為它們降低了丟失有價值數據的風險。從主文件中刪除一條記錄並不會從任何備份系統中刪除該記錄。通過正常的備份文件循環更新，備份記錄中的所有信息可能需要6個月才能完全消除。同時，只要備份記錄存在，它們就會獲得與我們其它記錄相同的隱私和安全保護。
      本政策項下從您處收到的所有請求將按照所適用的法律進行處理。
      <br />
      （一） 您的選擇
      <br />
      ·
      您始終可以選擇不提供信息。但是，如果您選擇不提供信息，您將無法使用任何需要您登錄的服務。
      <br />
      ·
      即使您已經同意我們處理您的個人信息，您仍可以隨時撤回該同意，並按照本政策中的說明選擇退出並進一步處理。這不會對之前已經收集、使用或披露的信息產生影響。即使您選擇退出，我們仍可收集和使用有關您在我們的網站上活動的非個人信息。
      <br />
      ·
      您可以改變您授權我們收集和處理您的個人信息的範圍。但請注意，對於部分類型的個人信息，例如實現數字藝術電商平台基本功能所必須的信息或者我們履行法律法規規定的義務所必需的信息，我們可能沒有辦法響應您改變授權範圍的請求。
      <br />
      · 您可以註銷您的登錄賬戶。
      <br />
      ·
      重要提示：如果您希望更正、修改或刪除由第三方（而非您直接）提供給我們的個人信息，您可以聯繫我們訪問、更正或從我們的文件中刪除您的信息。但是，由於您依據第三方網絡運營者或其他實體的隱私政策向他們提供了該等信息，我們還建議您與第三方網絡運營者或其他實體聯繫，因為我們不控制或不對那些實踐和隱私政策負責。
      <br />
      （二） 請勿追蹤
      <br />
      請勿追蹤（「請勿追蹤」）是用戶可以在某些網絡瀏覽器中設置的隱私偏好。請勿追蹤是一種讓用戶通知網站和服務，他們不希望通過網站或在線服務收集有關其網頁訪問的特定信息的方式。請注意，我們不回應或履行由網絡瀏覽器傳輸的請勿追蹤信號或類似的機制。
      <br />
      （三） 響應您的上述請求
      <br />
      為保障您的賬戶安全，您向我們提出上述請求時，我們可能會先要求您驗證自己的身份（如要求您提供書面請求或其他方式），然後再處理您的請求。
      對於您向我們提出的上述請求，我們將在合理時間內做出答復。如您不滿意我們的答復，還可以直接向我們的個人信息保護專職部門發起投訴。
      對於您合理的請求，我們原則上不收取費用，但對多次重復、超出合理限度的請求，我們將酌情收取一定費用。對於與您的身份不直接關聯的信息、無端重復信息，或者需要過多技術手段（例如，需要開發新系統或從根本上改變現行慣例）、給他人合法權益帶來風險或者不切實際的請求，我們可能會予以拒絕。
      <br />
      十一、國際傳輸
      <br />
      我們通過直接用戶輸入收到的任何個人信息都存儲在我們位於中國的服務器上。通過選擇訪問本數字藝術電商平台、使用服務或以其他方式向我們提供信息，您同意將此存儲在位於中國的服務器上。
      在符合以下情形之一的前提下，我們會在取得您的單獨同意並履行了法律規定的義務後，向境外實體提供您的個人信息：
      <br />
      · 依法律要求通過國家網信部門組織的安全評估；
      <br />
      · 按照國家網信部門的規定經專業機構進行個人信息保護認證；
      <br />
      ·
      與境外接收方訂立合同，約定雙方的權利和義務，並監督其個人信息處理活動達到法律規定的個人信息保護標準；
      <br />
      · 法律、行政法規或者國家網信部門規定的其他條件。
      <br />
      您應知曉，並非所有國家都提供與您居住地相同的數據保護和個人信息隱私標準。但是，我們將始終根據本政策處理和使用您的個人信息。
      <br />
      十二、我們如何處理未成年人的信息
      <br />
      保護未成年人的個人信息尤為重要。本數字藝術電商平台不是為吸引未成年人而設計的。未滿18歲或所在國法定成年年齡的用戶不應向我們的數字藝術電商平台提交信息，並且我們不會收集、接收或故意保留任何我們知道或有理由相信是未滿18歲或法定成年年齡的兒童的用戶的信息，除非這些信息是由家長或監護人提供的。如果兒童向我們提供了個人信息，兒童的父母或監護人應當聯繫我們，只要是他們想從我們的檔案中刪除這些信息。
      <br />
      十三、責任/遵守以及如何聯繫我們
      <br />
      我們致力於保護我們數字藝術電商平台用戶的隱私。我們發佈本政策是為了傳達此承諾的具體內容。
      如果您對本政策有任何疑問，或者希望瞭解更多有關我們隱私實踐的信息，請發送電子郵件至：service@hunter-group.io。我們將處理您的顧慮，並嘗試及時解決任何隱私問題。
      此外，我們會處理用戶可能對本政策或其實施情況提出的任何投訴或問題。如果您對我們的政策有任何投訴或疑問，請發送電子郵件至service@hunter-group.io。我們將在收到您的問詢後盡快回復您的意見或顧慮。
      <br />
      十四、其他網站的鏈接
      <br />
      我們的數字藝術電商平台可能包含其他第三方網站的鏈接。您應仔細查閱其他網站的隱私政策或通知和實踐，因為我們無法控制他們的隱私政策、通知或實踐，亦不對其負責。有關其他公司網站屬性的鏈接，請查閱其各自隱私條款，因為它們可能與本數字藝術電商平台上的隱私條款不同。
      <br />
      十五、政策更新與變更通知
      <br />
      為給您帶來更好的產品和服務體驗，我們持續努力改進我們的產品、服務及技術。在新的服務及業務流程變更，及隱私法律、法規變化時，我們會需要不時地修改或更新我們本政策。我們將始終更改本政策上的「最後更新」日期，以反映其上次修訂的時間。如果我們對本政策進行了有意義的更改，我們將通過電子郵件或數字藝術電商平台主頁上的通知在此處通知您。但是，我們將始終繼續根據法律和我們當前的隱私政策的約定保護您的個人信息。
      此外，在我們將個人信息用於未經您最初授權的任何新用途之前，我們將努力提供有關新用途的信息，並給予您選擇退出的機會。如果法律或合同要求您提供處理個人信息的同意，我們將努力遵守法律或合同。
      <br />
      本隱私政策最後更新於【2022】年【6】月【30】日。
    </div>
    <el-row type="flex" justify="center" slot="footer">
      <el-button @click="closed">取消</el-button>
      <el-button @click="closed" type="primary">已閱讀</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: 'newDialog',
  props: {
    isShowDialog: {
      default: false,
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
    },
  },
  created() {},
  methods: {
    closed() {
      this.$emit('showDialog')
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__title {
  font-weight: 600;
  font-size: 20px;
}
.newContent {
  width: 100%;
  height: 550px;
  padding: 0 40px;
  white-space: pre-wrap;
  overflow: auto;
  box-sizing: border-box;
  line-height: 26px;
  font-size: 14.5px;
  p {
    padding: 10px 0 20px 0;
    font-size: 18px;
    text-align: center;
  }
}
.newContent::-webkit-scrollbar {
  width: 0 !important;
}
</style>
